import React, { useEffect } from "react"
import { useCore } from "@app/hooks/useCore"

interface CustomHtmlProps {
  html: string
}

const CustomHtml: React.FC<CustomHtmlProps> = ({ html }) => {
  const {
    helpers: { isBrowser, isDomReady },
  } = useCore()

  // check if yoto is available on the window
  useEffect(() => {
    if (isBrowser && isDomReady && window.location.pathname.includes("customer-reviews")) {
      if (window.yotpo) {
        console.log("Yotpo is available")
        window.yotpo.refreshWidgets()
      } else {
        console.log("Yotpo is not available")
      }
    }
  }, [isBrowser, isDomReady])

  return <div className="Custom-HTML" dangerouslySetInnerHTML={{ __html: html }} />
}

export default CustomHtml
